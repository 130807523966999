<template>
	<Popover class="relative">
		<PopoverButton as="div" @mouseenter="setIsShowing(true)" @mouseleave="setIsShowing(false)">
			<slot />
		</PopoverButton>

		<transition
			enter-active-class="transition duration-200 ease-out"
			enter-from-class="opacity-0"
			enter-to-class="opacity-100"
			leave-active-class="transition duration-150 ease-in"
			leave-from-class="opacity-100"
			leave-to-class="opacity-0"
		>
			<div v-if="showPanel" @mouseenter="setIsInsidePanel(true)" @mouseleave="setIsInsidePanel(false)">
				<PopoverPanel static :class="tooltipClass">
					<div :class="popoverClass">
						<div :class="arrowClass" :style="arrowStyle" />
						<slot name="tooltip" />
					</div>
				</PopoverPanel>
			</div>
		</transition>
	</Popover>
</template>

<script setup lang="ts">
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
import {ref, computed} from "vue";

const props = withDefaults(defineProps<{
	arrow?: string,
	tooltipClass?: string,
	popoverCustomClass: string|undefined
}>(), {
	arrow: 'down',
	tooltipClass: 'absolute left-1/2 top-0'
})

const popoverClass = computed(() => {
	let classes = 'relative -left-1/2 px-4 py-2 text-xs rounded-md bg-jmi-purple1 text-jmi-purple4 z-10 select-none text-center whitespace-nowrap ';

	if (props.arrow === 'up') {
		classes += '-bottom-2';
	} else if (props.arrow === 'down') {
		classes += '-top-2';
	}

	if (props.popoverCustomClass) {
		classes += ' '+props.popoverCustomClass;
	}

	return classes;
});

const arrowClass = computed(() => {
	if (props.arrow === 'up') {
		return 'absolute w-0 h-0 -top-2 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-jmi-purple1';
	} else if (props.arrow === 'down') {
		return 'absolute w-0 h-0 -bottom-2 border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8 border-t-jmi-purple1';
	} else {
		return '';
	}
});

const arrowStyle = computed(() => {
	if (props.arrow === 'down' || props.arrow === 'up') {
		return 'left: calc(50% - 8px);';
	} else {
		return 'top: calc(50% - 8px);';
	}
});

const isShowing = ref(false);
const isInsidePanel = ref(false);

const showPanel = computed(() => {
	return isShowing.value || isInsidePanel.value;
});

const setIsShowing = (value) => {
	isShowing.value = value;
};

const setIsInsidePanel = (value) => {
	isInsidePanel.value = value;
};

</script>
